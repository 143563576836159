import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './apm.css';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';


const errorText: { [key: string]: string } = {
  "UserNotFound": 'This user was not found. Please check the email address you entered and try again. If you are sure you entered the correct email, please contact the IT Service Desk.',
  "Fallback": 'You did not manage to authenticate with your account. You will be sent to legacy authentication.'
}

const trustedURL: (string | RegExp)[] = [
  'https://[^/]+.iter.org(/.*)?$',
  //https://performancemanager5.successfactors.eu/
  'https?://performancemanager5.successfactors.eu(/.*)?$',
  //ariba
  'https?://[^/]+.ariba.com(/.*)?$',
]


const erroneousURL: (string | RegExp)[] = [
  // 'https://saml\.iter\.org/.*',
];


function isTrustedURL(url: string) {
  for (let i = 0; i < trustedURL.length; i++) {
    if (url.match(trustedURL[i])) {
      return true;
    }
  }

  // check if the URL is erroneous
  for (let i = 0; i < erroneousURL.length; i++) {
    if (url.match(erroneousURL[i])) {
      console.log('URL is erroneous:', url);
      return false;
    }
  }


  return false;
}


function App() {
  const [email, setEmail] = useState('');
  const [userInfo, setUserInfo] = useState<any | null>(null);
  const [logs, setLogs] = useState<string>("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const url = params.get('url');
  const debug = params.get('debug');
  var emailCookie = Cookies.get('UserEmail');
  const [isModalAcivationOpen, setIsModalOpen] = useState(false);
  const [isModalRegistrationOpen, setIsModalRegOpen] = useState(false);
  const [errorRet, setErrorRet] = useState('');

  const redirectAuth = (target: string) => {//redirection part
    var emailvar = email;
    //If page is reloaded, the email is not in the state, get it from the cookie
    if (email === '') {
      setEmail(emailCookie!);
      emailvar = emailCookie!;
    }

    //set requested url in a session cookie
    if (url){
      Cookies.set('RequestedURL', url!, { domain: 'wayf.iter.org', secure: true, sameSite: 'none' });
      console.log('RequestedURL cookie set to:', url);
    }

    if (target === 'azure') {
      Cookies.set('AADCapable', 'true', { domain: 'iter.org', secure: true, sameSite: 'none', expires: 5 });//expires in 5 days
      //console.log('AADCapable cookie set, redirecting to:', url);
      Cookies.set('UserEmail', emailvar, { domain: 'iter.org', secure: true, sameSite: 'none', expires: 30 });//expires in 30 days renewing each time the user logs comes back here (5 days)
    }

    else { //User is not in AAD
      Cookies.set('AADCapable', 'false', { domain: 'iter.org', secure: true, sameSite: 'none', expires: 1 });//expires in 1 day
      //console.log('AADCapable cookie set to false, redirecting to:', url);
      if (Cookies.get('UserEmail') === undefined) {
        Cookies.set('UserEmail', emailvar, { domain: 'iter.org', secure: true, sameSite: 'none', expires: 2 });//expires in 2 days NOT renewing
      }
      //TODO show the modal for activation
      // setIsModalOpen(true);
    }

    //Check if the URL is trusted
    if (url) {
      if (isTrustedURL(url)) {
        if (debug === 'true') {
          console.log('URL is trusted:', url);
        }
        document.location = url;
      }
      else {
        console.log('URL is not trusted:', url);
        document.location = 'https://bizz.iter.org/';
      }
      return;
    }
  }

  const getUserType = (data: any) => {
    setLogs('Information returned from AAD');
    //console.log('Information returned from AAD:', data);
    if (data.debug !== null)
      setUserInfo(data.debug);
    redirectAuth(data.target);
  }

  const preCheckEmail = (email: string) => {
    //Check email format with regex
    if (email && email.match(/^[\w\-\.]+@([\w-]+\.)+[\w]+$/)) {
      fetch(`/api/user?email=${email}&debug=${debug}`)
        .then(response => {
          if (response.status === 404 || response.status === 400) {
            setLogs('User is not in AAD');
            //console.log('User is not in AAD');
            if (debug === null)
              redirectAuth('fba');
            return
          }
          return response.json()
        })
        .then(data => {
          if (data === undefined) {
            //console.log('No data returned from AAD');
            redirectAuth('fba');
            return
          }
          //console.log(data);
          getUserType(data);
          setUserInfo(data);
        })
        .catch(error => console.error('Error:', error));
    }
  }

  const setTheEmail = (email: string) => {

    //Check if email is valid
    if (email.match(/^[\w\-\.]+@([\w-]+\.)+[\w]+$/)) {
      setEmail(email);
    }
    else {
      setEmail('');
    }
  }

  const handleClick = () => {
    //console.log('Checking user:', email);

    if (debug !== 'true') {
      setIsButtonClicked(true);
    }

    preCheckEmail(email);
  };

  useEffect(() => {//this runs only once on load of the page
    
    const redirectURL = () => {
      if (url && isTrustedURL(url)) {
        document.location = url;
      }else if
      (Cookies.get('RequestedURL') && isTrustedURL(Cookies.get('RequestedURL')!)) {
        document.location = Cookies.get('RequestedURL')!;
      }
      else {
        document.location = 'https://bizz.iter.org/';
      }
    }

    const isLoggedIn = () => {
      // if (document.visibilityState === 'visible' || Cookies.get('AADCapable') === undefined){ //only redirect if the page is hidden and aadcapable is set
      //   //try again in 3sec
      //   setTimeout(() => isLoggedIn(), 3000 + Math.random() * 1000);
      //   return;
      // }

      //for now, redirect anyway as trial. 
      redirectURL();
      return;

      const mrhSession = Cookies.get('isLoggedIn');
      if (mrhSession === 'true') {
        // Redirect to the desired URL
        redirectURL();
        console.log('Session is established, redirecting now', url);
        return;
      }
    };

    const handleVisibilityChange = (emailCookie: string | undefined) => {
      if (document.visibilityState === 'visible' && emailCookie !== undefined) {
          preCheckEmail(emailCookie);
      }
    }
    //check if we're already logged in in 3sec
    // setTimeout(() => isLoggedIn(), 4000 + Math.random() * 1000);
    document.addEventListener('visibilitychange', () => handleVisibilityChange(emailCookie));

    //If &reset=true is in the URL, remove the cookies
    if (params.get('reset') === 'true') {
      Cookies.remove('UserEmail', { domain: 'iter.org' });
      Cookies.remove('AADCapable', { domain: 'iter.org' });
      emailCookie = '';
    }

    if (params.get('fallback') === 'true') {
      Cookies.set('AADCapable', 'false', { domain: 'iter.org', secure: true, sameSite: 'none', expires: 1 });//expires in 1 day
      //redirect user to its url get parameter after check it was trusted domain (ends with iter.org)
      //TODO show the modal for error.
      setErrorRet('Fallback');
      redirectURL();
      return;
    }

    if (params.get('error') === 'UserNotFound') {
      Cookies.set('AADCapable', 'false', { domain: 'iter.org', secure: true, sameSite: 'none', expires: 1 });//expires in 1 day
      //redirect user to its url get parameter after check it was trusted domain (ends with iter.org)
      //TODO show the modal for error.
      console.log('UserNotFound');
      setErrorRet('UserNotFound');
      redirectURL();
      return;
    }
    if (emailCookie) {//if the email is in the cookie, check it and redirect
      if (document.visibilityState === 'visible') {
        preCheckEmail(emailCookie);
      }
    }

  }, []);

  const calculateDays = () => {
    const today = new Date();
    const futureDate = new Date('2025-01-01');
    const diffTime = Math.abs(futureDate.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
  return (
    <div className="App">
      <div className='warning-box'>
        <img src="/iterlogo.svg" className="warning-logo" alt="logo" />
        <div className='warning-text'><h1>New authentication!</h1>We are improving our authentication systems with new simpler and safer steps.</div>
      </div>
      <div className="App-logo">
        <img src="/iterlogo.svg" className="bg-logo" alt="logo" />
      </div>
      <div className="App-content">
        <div className="centered-div">
          {/* <img src="./iterlogo.svg" className="app-logo" alt="logo" /> */}

          <h1>ITER Organization supports multiple authentications</h1>
          <p>Your email will help us send you to the right place.</p>
          {errorRet && <div className="alert alert-danger" role="alert"> Error: {errorText[errorRet]} </div>}
          <div className="input-wrapper">
            <form className="form-inline" onSubmit={e => { e.preventDefault(); }}>
              <div className="input-icon-wrapper">
                <FontAwesomeIcon icon={faEnvelope} className="input-icon" />

                <input type="email" name="email" className="form-control" placeholder="Your email" aria-label="Email" onChange={e => setTheEmail(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleClick();
                    }
                  }} />

              </div>
              <button onClick={handleClick} disabled={email === '' || isButtonClicked} className="" >{isButtonClicked ? (
                <>
                  Redirecting... <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                </>
              ) : <> Let's go!</>}</button>
            </form>
          </div>
        </div>
      </div>
      {debug !== null && logs && (
        <div className="mt-4 debug">
          {debug !== null && logs && (
            <div className="alert alert-warning" role="alert">
              <h5>Logs</h5>
              <p>{logs}</p>
              <p>{userInfo && userInfo.result}</p>
            </div>
          )}
          {debug !== null && (
            <div>
              <h5>Debug</h5>
              <pre>{JSON.stringify(userInfo, null, 2)}</pre>
              <h5>Redirect to: <a href='{url}'>{url}</a></h5>
              <a href={url!} className="btn btn-primary">Continue</a>
            </div>
          )}
        </div>
      )}

      {debug !== null && (
        <div>
          <button onClick={() => setIsModalOpen(true)}>Open Modal Activation</button>
          <button onClick={() => setIsModalRegOpen(true)}>Open Modal Registration</button>
        </div>
      )
      }
      <Modal isOpen={isModalAcivationOpen} onRequestClose={() => setIsModalOpen(false)} className="my-modal">
        <h2>Request Account Activation</h2>
        <p>Your account is not yet activated in the ITER Entra directory.</p>
        <p>To continue using ITER applications, you need to <b>activate your account</b>.</p>
        <p>Activation is a one-time process that will take less than 5 minutes. You will receive an email with an activation link you shall click on.</p>
        <p>Click the button below to request activation.</p>
        <p>
          <button onClick={() => setIsModalOpen(false)}>Send activation by email</button></p>
        <p className='btn btn-secondary'>Activate later ({calculateDays()} days left)</p>
      </Modal>

      <Modal isOpen={isModalRegistrationOpen} onRequestClose={() => setIsModalRegOpen(false)} className="my-modal">
        <h2>User unknown</h2>
        <p>This email does not exist in our directory. Make sure you entered your professional/organizational email.</p>
        <p></p>
        <p>If you need access to an ITER application, you can request an account.</p>

        <p><button onClick={() => setIsModalRegOpen(false)}>Request account</button></p>
        <p><button onClick={() => setIsModalRegOpen(false)}>Close</button></p>
      </Modal>
    </div>
  );
}

export default App;